import "./body.css"
import React from 'react'
import { BodyContainer } from '../../styles/body'
// import body1 from "../images/body-images/body2.jpg"


const Body = () => {
  
  
  return (
    <BodyContainer className="body_pic">
      
    </BodyContainer>
  )
}

export default Body