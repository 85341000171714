import { Grid } from '@mui/material'
import React from 'react'
import { CourseTypographyTitle, CourseTyporaphyDescription, GridBox } from '../../styles/courses/Index'
import "./courses.css"

const Courses = () => {
 

  return (
    <>
    <Grid container spacing={3}>
        {
            coursesItem.map((item, index)=>{
                return(
                    <Grid item sm={4} xs={12} key={index}>
                        <GridBox className='course'>
                            <CourseTypographyTitle>{item.title}</CourseTypographyTitle>
                            <CourseTyporaphyDescription>
                                {item.description} 
                            </CourseTyporaphyDescription>
                            <CourseTyporaphyDescription sx={{color: '#f42277'}}>{item.duration}</CourseTyporaphyDescription>
                        </GridBox>
                    </Grid>
                )
            })  
        }
    </Grid>
    </>
  )
}

const coursesItem = [
    {
        title: "Nazra Quran",
        description: `The best among you(Muslims) are those who learn the Quran and teach it.`,
        duration: 'Duration: Up To You'
    },
    {
        title: "Quran Kareem with Phonetics",
        description: `We will teach you Tajveed of Quran Kareem`,
        duration: 'Duration: 3 Months'
    },
    {
        title: "Life of Holy Prophet (PBUH)",
        description: `We will teach you Seerat-e-Nabvi. What can we get from Seerat-e-Nabvi and how we can improve of daily life by using 
                    the principles of Seerat-e-Nabvi`,
        duration: 'Duration: 3 Months'
    },
    {
        title: "Principles To Study The Hadith",
        description: `Basically, it is part of hadith courses.
                    In this course, we teach you principles,
                    and how to study the hadith books.`,
        duration: 'Duration: Two Months'
    },
    {
        title: "Collection of Forty Hadith on Different Topics",
        description: `In this course, we teach you different collections of 40 selected Hadiths compiled by great Scholars of world.`,
        duration: 'Duration: 50 days for each collection'
    },
    {
        title: "Arbaeen",
        description: `In this course, Arbaeen will be taught to you. This book is compiled by a prominent scholar called Imam Navavi (R.A)`,
        duration: 'Duration: Two Month'
    },
    {
        title: "Education",
        description: `In this course, we will learn the importance of Education in the light of hadith e Nabvi.
                    Hazrat Muhammad (PBUH) said "The seeking of knowledge is obligatory for every Muslim".`,
        duration: 'Duration: One Months'
    },
    {
        title: "Health",
        description: `In this course, we will learn, how we can improve our health in the light of hadith e Nabvi.`,
        duration: 'Duration: One Months'
    },
    {
        title: "Eithics",
        description: `In this course, we will learn ethics in the light of hadith e Nabvi.`,
        duration: 'Duration: One Months'
    },
    {
        title: "Quran Kareem with Translation",
        description: `In this course you will learn Quran Kareem with its Translation.`,
        duration: 'Duration: Up to you'
    },
    {
        title: "Memorization of Quran Kareem(Phase 1)",
        description: `In this course you will memorize last 30 Surahs of Quran Kareem.`,
        duration: 'Duration: 3 Months'
    },
    {
        title: "Memorization of Quran Kareem(Phase 2)",
        description: `In this course you will memorize some selected Surahs of Quran Kareem
        Like Surah Yasmeen/سورة ىسين , Surah Mulk / سورة الملك ,Surah Rehman/سورة الرحمن ,Surah waqia /سورة الواقعه ,Surah Muzzamil/سورة المزمل `,
        duration: 'Duration: 6 Months'
    },
    {
        title: "Quran Kareem with Tafseer ",
        description: `We are offering Quranic studies in detail.You will study Quran Kareem with various point of views.This Tafseer Course will enhance your understanding about Quran Kareem and different aspects of life.`,
        duration: 'Duration: Up to you'
    },
]

export default Courses
